import { createVueApp } from '../vue'
import { DOMListen, onDomContentLoaded } from '../dom_utils'
import type { Component } from 'vue'

import { RegisteredComponents, type RegisteredComponentsProps } from './components'
import { useDialog } from '@component-utils/dialogs'

type RegisteredComponentName = keyof typeof RegisteredComponents

type VueComponentElement = HTMLElement & {
  dataset: {
    component: RegisteredComponentName
    props: string
    lazy?: 'true',
    page?: 'true'
  }
}

type HookTypes = 'beforeMount' | 'beforeCreate'
type HookCallbacks<T extends RegisteredComponentName> = {
  beforeMount: (props: RegisteredComponentsProps[T], app: ReturnType<typeof createVueApp>) => void | Promise<void>
  beforeCreate: (props: RegisteredComponentsProps[T]) => void | Promise<void>
}

type Hook<T extends HookTypes, U extends RegisteredComponentName> = {
  componentName: U
  callback: HookCallbacks<U>[T]
  type: T
}

const hooks: Hook<HookTypes, RegisteredComponentName>[] = []

const registerHook = <T extends HookTypes>(type: T) => {
  return <U extends RegisteredComponentName>(componentName: U, callback: HookCallbacks<U>[T]) => {
    hooks.push({ type, componentName, callback })
  }
}

export const avvBeforeMount = registerHook('beforeMount')
export const avvBeforeCreate = registerHook('beforeCreate')

const isHook = <T extends HookTypes, U extends RegisteredComponentName>(hook: Hook<T, U>, type: T, componentName: U): hook is Hook<T, U> => hook.type === type && hook.componentName === componentName

const runHooks = async <T extends RegisteredComponentName, U extends keyof HookCallbacks<T>>(hookType: U, componentName: T, ...hookParams: Parameters<HookCallbacks<T>[U]>) => {
  for (const hook of hooks) {
    if (isHook(hook, hookType, componentName)) await hook.callback(...hookParams)
  }
}

const base64ToJsonToObject = <T>(string: string) => {
  return JSON.parse(new TextDecoder().decode(Uint8Array.from(atob(string), (c) => c.charCodeAt(0)))) as T
}

const loadComponents = (container: Document | HTMLElement) => {
  const elements = Array.from(container.querySelectorAll<VueComponentElement>('vue-component:not(.error)'))

  elements.forEach((element) => {
    // If loading or anything fails, display error message & block element from loading again using the error class
    const displayError = (e: unknown) => {
      console.error(e)

      element.classList.add('error')
      element.innerHTML = '<i class="material-symbols-outlined" aria-hidden="true">exclamation</i><div>Element has failed to load</div>'
    }

    try {
      // Temporary element carries the component URL and the props as Base64 UTF8 JSON
      const componentName = element.dataset.component
      const componentLazy = element.dataset.lazy === 'true'
      const componentPage = element.dataset.page === 'true'
      const componentProps = base64ToJsonToObject<RegisteredComponentsProps[typeof componentName]>(element.dataset.props)

      // Get the container
      const container = element.parentNode as HTMLElement
      const temporary = element.children[0]

      if (componentPage) {
        // Move co
        document.body.appendChild(temporary)
      }

      const fetchComponentAndMount = async () => {
        try {
          const component = await RegisteredComponents[componentName]().then((componentImport) => (componentImport as { default: Component }).default)

          // Run all beforeCreate hooks
          await runHooks('beforeCreate', componentName, componentProps)

          if (componentPage) {
            // Append mounted hook to remove the temporary page
            componentProps.onVnodeMounted = () => temporary.remove()
          }

          // Import the component from the component URL and mount it to the parent element of our temporary element
          const app = createVueApp(component, componentProps)

          // Run all beforeMount
          await runHooks('beforeMount', componentName, componentProps, app)

          app.mount(container)
        } catch (e) {
          displayError(e)
        }
      }

      if (componentLazy) {
        const observer = new ResizeObserver((entries) => {
          if (entries.some((entry) => entry.contentRect.width !== 0)) {
            void fetchComponentAndMount()

            observer.disconnect()
          }
        })

        observer.observe(element)
      } else {
        void fetchComponentAndMount()
      }
    } catch (e) {
      displayError(e)
    }
  })
}

// Automatically load components
onDomContentLoaded(() => loadComponents(document))
DOMListen('mainContentChange', (event) => loadComponents(event.detail.element))

async function useDialogUnsafe (componentName: RegisteredComponentName, props: Record<string, unknown>) {
  const component = await RegisteredComponents[componentName]().then((componentImport) => (componentImport as { default: Component }).default)

  useDialog(component, props)
}

declare global {
  interface Window {
    useDialogUnsafe: typeof useDialogUnsafe
  }
}

window.useDialogUnsafe = useDialogUnsafe

export default {}
