import type { AllowedComponentProps, Component, VNodeProps } from 'vue'

// Add components here to be able to use them in .html.erb files using the VueComponent
export const RegisteredComponents = {
  Chatbot: () => import('../chatbot/components/Chatbot.vue'),
  DocuSignSendFolder: () => import('../docu_sign_send_folder/DocuSignSendFolder.vue'),
  TemplateAutoName: () => import('../../views/templates/components/Advanced/Advanced.vue'),
  TemplateWebhookTriggers: () => import('../../views/templates/components/Advanced/AllowedTriggers.vue'),
  TemplateMetadata: () => import('../../views/templates/components/Advanced/Metadata.vue'),
  TemplateAttributes: () => import('../../views/templates/components/Attributes/App.vue'),
  TemplateStyles: () => import('../../views/templates/components/Styles/StylesLoader.vue'),
  TemplateNotes: () => import('../../views/templates/components/Notes/App.vue'),
  TemplateCharactersWhitelist: () => import('../../views/templates/components/Advanced/CharactersWhitelist.vue'),
  TemplateCharacterLimitInput: () => import('../../views/templates/components/Advanced/CharacterLimitInput.vue'),
  TemplateValidationControllers: () => import('../../views/templates/components/Advanced/ValidationControllers.vue'),
  TemplateSummary: () => import('../../views/templates/components/Summary/App.vue'),
  AutoNameInput: () => import('../auto_name_input/AutoNameInput.vue'),
  CustomReports: () => import('../custom_reports/CustomReports.vue'),
  DealEdit: () => import('../deals/DealEdit.vue'),
  DealIndex: () => import('../deals/DealIndex.vue'),
  DealFormResponse: () => import('../deals/DealFormResponse.vue'),
  DealDashboard: () => import('../deals/DealDashboard.vue'),
  ArchiveDialog: () => import('../archive_dialog/ArchiveDialog.vue'),
  MoveToFolderDialog: () => import('../move_to_folder_dialog/MoveToFolderDialog.vue'),
  EmailSmartSubject: () => import('../email_templates/SmartSubject.vue'),
  PropertiesSidebar: () => import('../../features/properties_sidebar/PropertiesSidebar.vue'),
  FileUploadDialog: () => import('../../features/drive/file_upload_dialog/FileUploadDialog.vue'),
  ToggleUserModal: () => import('../../features/users/ToggleUserModal.vue'),
  TemplatePacksDashboard: () => import('../../features/template_packs/Dashboard.vue'),
  TemplatePack: () => import('../../features/template_packs/TemplatePack.vue'),
  UserSettings: () => import('../../features/user_settings/Dashboard.vue'),
  AdminSettings: () => import('../../features/admin_settings/Dashboard.vue'),
  UserLogin: () => import('../../features/devise/Dashboard.vue'),
  'Datasheets/Form': () => import('../../features/datasheets/Form.vue'),
  MetadataSettings: () => import('../../features/organisation/settings/metadata/MetadataSettings.vue'),
  WhiteLabelSettings: () => import('../../features/organisation/settings/WhiteLabel.vue'),
  AiLogsSettings: () => import('../../features/organisation/settings/ai_logs/AiLogsTable.vue'),
  AiLogsDetail: () => import('../../features/organisation/settings/ai_logs/AiLogsDetail.vue'),
  OrganisationDetails: () => import('../../features/organisation/settings/OrganisationDetails.vue'),
  EmailTemplates: ()=>import('../../features/organisation/settings/email_templates/EmailTemplates.vue'),
  EditTemplate: ()=>import('../../features/organisation/settings/email_templates/EditTemplate.vue'),
  PreviewEmail: ()=>import('../../features/organisation/settings/email_templates/PreviewEmail.vue'),
  SalesForceSettings: () => import('../../features/organisation/settings/SalesForce.vue'),
  MigrationTools: () => import('../../features/organisation/settings/MigrationTools.vue'),
  SsoSettings: () => import('../../features/organisation/settings/SingleSignOn.vue'),
  DriveSettings: () => import('../../features/organisation/settings/DriveSettings.vue'),
  'Organisation/SynchronizedDatasheets': () => import('../../features/organisation/settings/SynchronizedDatasheets.vue'),
  AssetsSettings: () => import('../../features/organisation/settings/assets/Assets.vue'),
  ImanageWork: () => import('../../features/organisation/settings/iManage/iManageWork.vue'),
  EmailSettings: () => import('../../features/organisation/settings/email_settings/EmailSettings.vue'),
  DefaultUserSettings: () => import('../../features/organisation/settings/DefaultUserSettings.vue'),
  ApiAccess: () => import('../../features/organisation/settings/api_access/ApiAccess.vue'),
  OrganisationAdmins: () => import('../../features/organisation/settings/OrganisationAdmins.vue'),
  AiPrompts: () => import('../../features/organisation/settings/ai_prompts/AiPrompts.vue'),
  AiPromptsEdit: () => import('../../features/organisation/settings/ai_prompts/AiPromptsEdit.vue'),
  AiPromptsHistory: () => import('../../features/organisation/settings/ai_prompts/AiPromptsHistory.vue'),
  AiPromptsTest: () => import('../../features/organisation/settings/ai_prompts/AiPromptsTest.vue')

} as const

type ComponentProps<C extends Component> = C extends new (...args: any) => any ? Omit<InstanceType<C>['$props'], keyof VNodeProps | keyof AllowedComponentProps> : never

export type RegisteredComponentsProps = {
  [T in keyof typeof RegisteredComponents]: ComponentProps<Awaited<ReturnType<(typeof RegisteredComponents)[T]>>['default']>
}
